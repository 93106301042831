import React from "react"
import Viewport from "../../components/common/viewport"
import PostContent from "../content/post/post-content"
import SEO from "../seo"

const Post = props => {
  const page = props.pageContext.post

  if (!page) return

  return (
    <Viewport>
      <PostContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default Post

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.post.seoTitle}
    description={pageContext.post.seoDescription}
    keywords={pageContext.post.seoKeywords}
    schemaData={pageContext.post}
  />
)
