import React from "react"
import PropTypes from "prop-types"
import IntroSection from "./intro-section"

const PostContent = ({ pageContext }) => {
  return (
    <>
      <IntroSection post={pageContext.post} />
    </>
  )
}

PostContent.propTypes = {
  pageContext: PropTypes.object,
}

export default PostContent
