import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Section from "../../common/section"
import Theme from "../../../theme/theme"
import Arrow from "../../svg/arrow"
import Shape from "../../svg/shape"
import { GatsbyImage } from "gatsby-plugin-image"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
`

const IntroContent = styled.div`
  position: relative;
  margin: 15rem auto 0;
  max-width: 80%;
`

const PostHeader = styled.div`
  max-width: 130rem;
`

const PostData = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 130rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
  }
`

const PostDate = styled.div`
  position: relative;
  top: -1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 5rem;
  font-size: 1.8rem;
  letter-spacing: 0.36rem;
  margin-top: 3rem;
  padding-bottom: 0.2rem;
  border: 0.1rem solid ${props => props.theme.colors.gray3};
  border-radius: 2.5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-top: 0;
    margin-left: auto;
  }
`

const PostCategories = styled.ul`
  display: flex;
  font-size: 1.8rem;
  text-transform: uppercase;

  li {
    margin-right: 5rem;
    letter-spacing: 0.36rem;
  }
`

const PostTitle = styled.h1`
  font-size: 5rem;
  line-height: 6rem;
  margin-bottom: 7rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 10rem;
    line-height: 12rem;
    margin-bottom: 14rem;
    padding-right: 15rem;
  }
`

const PostIntro = styled.div`
  font-size: 2.6rem;
  line-height: 3.8rem;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 3.6rem;
    line-height: 4.8rem;
    margin-bottom: 10rem;
  }

  a {
    color: ${props => props.theme.colors.main};
    text-decoration: underline;
  }
`

const PostContent = styled.div`
  font-size: 2.6rem;
  line-height: 3.8rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 3.6rem;
    line-height: 4.8rem;
    padding-right: 5rem;
  }

  a {
    color: ${props => props.theme.colors.main};
    text-decoration: underline;
  }

  p {
    margin-top: 4rem;
  }

  h2 {
    font-size: 5rem;
    line-height: 6rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 6.8rem;
      line-height: 7.8rem;
    }
  }

  h3 {
    position: relative;
    font-size: 3.6rem;
    font-family: "darker_grotesquebold", sans-serif;
    margin-top: 8rem;
    padding-left: 3.5rem;

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      width: 2rem;
      margin-top: 0.5rem;
      transform: translate3d(0, -50%, 0) rotate(90deg);

      path {
        fill: ${props => props.theme.colors.main};
      }
    }
  }
`

const PostList = styled.ul`
  margin-left: 5rem;
  margin-top: 5rem;
  list-style-type: none;

  li {
    position: relative;
  }

  svg {
    position: absolute;
    top: 1rem;
    left: -3rem;
    width: 2rem;
    transform: rotate(90deg);

    path {
      fill: ${props => props.theme.colors.text2};
    }
  }

  h3 {
    position: relative;
    font-family: "darker_grotesquelight", sans-serif;
    margin-top: 0;
  }

  p {
    color: ${props => props.theme.colors.text2};
    font-size: 2.8rem;
    margin-top: 2rem;
  }
`

const ImageContainer = styled.div`
  display: flex;
  margin-top: 8rem;

  p {
    margin-top: 0;
    margin-left: 2rem;
  }
`

const PostContentContainer = styled.div`
  display: flex;
`

const StyledShape1 = styled(Shape)`
  position: absolute;
  top: 0;
  right: -3rem;
  transform: rotate(-180deg);
  z-index: -1;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: 28rem;
  right: 6.5rem;
  z-index: -1;
  transform: rotate(-90deg);
`

const IntroSection = ({ post }) => {
  if (!post) return

  const postDate = new Date(post.createdAt)
  const postDay = postDate.getUTCDay()
  const postMonth = postDate.getMonth()
  const postYear = postDate.getFullYear()

  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <h3>
            <Arrow />
            {children}
          </h3>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const image = post.content.references.find(
          reference => reference.contentful_id === node.data.target.sys.id,
        )?.gatsbyImageData
        return (
          <ImageContainer>
            <GatsbyImage alt="" image={image} />
          </ImageContainer>
        )
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        if (!children) return

        return (
          <PostList>
            {children.map((child, index) => (
              <li key={index}>
                <Arrow />
                {child.props.children}
              </li>
            ))}
          </PostList>
        )
      },
    },
  }

  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.1}
    >
      <IntroContent>
        <PostHeader>
          <PostData>
            {post.categories && (
              <PostCategories>
                {post.categories.map((category, index) => (
                  <li key={index}>{category}</li>
                ))}
              </PostCategories>
            )}
            <PostDate>{`${postDay}.${postMonth}.${postYear}`}</PostDate>
          </PostData>
          <PostTitle>{post.title}</PostTitle>
          {post.intro && (
            <PostIntro>
              {documentToReactComponents(JSON.parse(post.intro.raw))}
            </PostIntro>
          )}
        </PostHeader>
        {post.content && (
          <PostContentContainer>
            <PostContent>
              {documentToReactComponents(JSON.parse(post.content.raw), options)}
            </PostContent>
          </PostContentContainer>
        )}
        <StyledShape1 isMainColor={true} />
        <StyledShape2 isSecondColor={true} />
      </IntroContent>
    </StyledSection>
  )
}

IntroSection.propTypes = {
  project: PropTypes.object,
  isLight: PropTypes.bool,
}

export default IntroSection
